import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Dusk from "../components/backgrounds/Dusk"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "two_guys_long_fish.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2: file(relativePath: { eq: "steve_rooster_fish.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3: file(relativePath: { eq: "steve_head.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image4: file(relativePath: { eq: "dan_rooster_fish.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image5: file(relativePath: { eq: "oz_yellow_fin.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image6: file(relativePath: { eq: "sunfish.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image7: file(relativePath: { eq: "ladyfish.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image8: file(relativePath: { eq: "baja_rocks.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Gallery data={data} />}
  />
)

export const StyledGallery = styled.div`
  height: 100%;
  width: 100vw;
  position: relative;
  .galleryWrapper {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .galleryContents {
    min-width: 50vw;
    text-align: center;
    p {
      margin: 50px auto;
    }
  }
  .galleryGrid {
    max-width: 900px;
    display: grid;
    grid-gap: 2vw;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    img {
      margin: 0;
    }
  }
  @media (max-width: 600px) {
    .galleryContents {
      width: 90vw;
    }
  }
`

const Gallery = ({ data }) => {
  return (
    <StyledGallery>
      <Dusk fixed overlayColor="darkGray" overlayOpacity={0.7}>
        <div className="galleryWrapper">
          <div className="galleryContents">
            <h2
              className="decTitle"
              style={{ width: "min-content", margin: "50px auto" }}
            >
              Gallery
            </h2>
            <div className="galleryGrid">
              {Object.values(data).map(img => (
                <Img fluid={img.childImageSharp.fluid} />
              ))}
            </div>
            <p>
              The waters of Baja are home to a wide variety of fish. On our
              trips we've caught species such as: Yellowtail, Yellow Fin Tuna,
              Dorado, Wahoo, Marlin, Sailfish, Rooster Fish, Grouper, Snapper,
              Cabria, Amberjack and more. Every day is a new experience! Check
              our gallery for pictures from some of our recent trips.
            </p>
          </div>
        </div>
      </Dusk>
    </StyledGallery>
  )
}
