import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({
  className,
  children,
  imageData,
  overlayColor,
  overlayOpacity,
  fixed,
  loading,
  backgroundPosition,
  mobileBackgroundPosition,
}) => (
  <BackgroundWrapper fixed={fixed}>
    <StyledBackgroundImage
      Tag="section"
      className={`${className} backgroundImage`}
      fluid={imageData}
      loading={loading || "lazy"}
      backgroundPosition={backgroundPosition}
      mobileBackgroundPosition={mobileBackgroundPosition}
    >
      <Overlay overlayColor={overlayColor} overlayOpacity={overlayOpacity} />
      <ContentContainer>{children}</ContentContainer>
    </StyledBackgroundImage>
  </BackgroundWrapper>
)

const BackgroundWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-repeat: repeat-y;
  background-size: cover;
  .backgroundImage,
  .backgroundImage::before,
  .backgroundImage::after {
    background-attachment: ${props => (props.fixed ? "fixed" : "scroll")};
  }
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    min-height: 25vh;
    .backgroundImage,
    .backgroundImage::before,
    .backgroundImage::after {
      background-attachment: scroll;
      ::before {
        background-attachment: scroll;
      }
      ::after {
        background-attachment: scroll;
      }
    }
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
  background-position: ${props => props.backgroundPosition || "50% 75%"};
  background-repeat: repeat-y;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    background-position: ${props =>
      props.mobileBackgroundPosition || "50% 75%"};
  }
`

const ContentContainer = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    min-height: 25vh;
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: ${props =>
    props.overlayColor
      ? props.theme[props.overlayColor](props.overlayOpacity)
      : `rgba(12, 18, 20, ${props.overlayOpacity})`};
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

export default BackgroundSection
