import React from "react"
import styled from "styled-components"

import BoatTrails from "../components/backgrounds/BoatTrails"
import { StyledGallery } from "./Gallery"

export const SimpleButton = styled.button`
  width: min-content;
  white-space: nowrap;
  min-height: 40px;
  max-width: ${props => props.maxWidth};
  border: none;
  color: white;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  background: transparent;
  transition: 0.9s all;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.8rem;
  padding: 4px 10px;
  outline: none;
  border-radius: 2px;
  &:hover {
    color: ${props => props.theme.turquoise(1)};
    border-color: ${props => props.theme.turquoise(1)};
    background-color: rgba(0, 0, 0, 0.3);
  }
`
export const StyledLinkButton = styled.a`
  width: min-content;
  white-space: nowrap;
  min-height: 40px;
  max-width: ${props => props.maxWidth};
  border: none;
  color: white;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  background: transparent;
  transition: 0.9s all;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.8rem;
  padding: 10px;
  outline: none;
  border-radius: 2px;
  &:hover {
    color: ${props => props.theme.turquoise(1)};
    border-color: ${props => props.theme.turquoise(1)};
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const StyledContactForm = styled.form`
  display: block;
  text-align: left;
  p {
    margin: 0;
    width: 100%;
  }
  .hidden {
    height: 0px;
    display: hidden;
    visibility: hidden;
  }

  label {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: left;
    row-gap: 8px;
    font-size: 0.8em;
    :focus-within {
      span.labelText {
        color: ${props => props.theme.turquoise()};
        transition: color 0.4s;
      }
    }
  }
  input,
  textarea {
    width: 100%;
    background: transparent;
    border-color: white;
    border-style: solid;
    border-width: 0 0 3px 0;
    padding: 0;
    outline: none;
    font-size: 2em;
    color: white;
    border-radius: 0;
  }

  input {
    height: 30px;
  }

  textarea {
    width: 100%;
    height: 90px;
  }

  .buttonContainer {
    margin-top: 12px;
    text-align: right;
    button {
      background: transparent;
      border-color: white;
      border-radius: 2px;
      color: white;
      padding: 0 2vw;
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.turquoise()};
        background: white;
      }
    }
  }
`

const ContactForm = () => {
  return (
    <StyledContactForm
      id="contact"
      name="contact"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <p className="hidden">
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </p>
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label>
          <span className="labelText">Name: </span>
          <input type="text" name="name" />
        </label>
      </p>
      <p>
        <label>
          <span className="labelText">Email: </span>
          <input type="email" name="email" />
        </label>
      </p>
      <p>
        <label>
          <span className="labelText">Message: </span>{" "}
          <textarea name="message" />
        </label>
      </p>
      <div className="buttonContainer">
        <SimpleButton type="submit">Send</SimpleButton>
      </div>
    </StyledContactForm>
  )
}

const Footer = ({ siteTitle }) => (
  <footer>
    <StyledGallery>
      <BoatTrails overlayColor="darkGreenBlue" overlayOpacity={0.8}>
        <div className="galleryWrapper">
          <div className="galleryContents">
            <h2
              className="decTitle"
              style={{ width: "min-content", margin: "50px auto" }}
            >
              Contact
            </h2>
            <ContactForm />
            <p style={{ textAlign: "center" }}>
              You may also reach Oz directly at: <br /> oz@ozsadventures.com{" "}
              <br /> or 619.865.0067
              <br />
              <br />© 2022 Oz's Baja Adventures
            </p>
          </div>
        </div>
      </BoatTrails>
    </StyledGallery>
  </footer>
)

export default Footer
