import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundSection from "./BackgroundImg"

const BoatTrails = ({
  className,
  children,
  overlayColor,
  overlayOpacity,
  fixed,
  backgroundPosition,
}) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "boat_trails.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundSection
          className={className}
          imageData={imageData}
          children={children}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
          fixed={fixed}
          backgroundPosition={backgroundPosition}
        />
      )
    }}
  />
)

export default BoatTrails
