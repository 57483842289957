/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Footer from "./footer"
import "./layout.css"

export const themeStyles = {
  darkGreenBlue: function (opacity = 1) {
    return `rgba(12, 58, 66, ${opacity})`
  },
  darkGray: function (opacity = 1) {
    return `rgba(12, 21, 28, ${opacity})`
  },
  turquoise: function (opacity = 1) {
    return `rgba(102, 204, 255, ${opacity})`
  },
  mobileBreakPoint: "700px",
  headerHeight: "50px",
  minHeight: `calc(100vh - 2rem)`,
}

const GlobalStyle = createGlobalStyle`
  html::-webkit-scrollbar  {
    display: none; 
  }
  html {
    box-sizing: border-box;
    font-family: Verdana, sans-serif;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-family: Verdana, sans-serif;
  } 
  button {
    font-family: Verdana, sans-serif;
  }
  a {
    text-decoration: none;
    letter-spacing: 5px;
    color: white;
    cursor: pointer;
    &:hover{
      color: ${themeStyles.turquoise()}
    };
  } 
  h1, h2, h3, h4, button {
    font-family: Verdana, sans-serif;
    text-transform: uppercase;
    letter-spacing: .25em;
    color: white;
    font-size: 1.2rem;
  }
  p {
    font-family: Verdana, sans-serif;
    color: white;
    width: 80%;
    max-width: 900px;
    margin: 0;
    line-height: 1.5rem;
    text-align: left;
    @media (max-width: 600px) {
      font-size: 12px;
      width: 90vw;
    }
  }
  h2.decTitle {
    color: white;
    font-size: 30px;
    letter-spacing: 6px;
    text-align: center;
    margin: 0;
    padding: 8px;
    text-transform: uppercase;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: white;
  } 
  
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={themeStyles}>
      <div>
        <GlobalStyle />
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div
          style={{
            padding: `0`,
            margin: "0",
          }}
        >
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
